
import { defineComponent } from 'vue';
import { notification } from 'ant-design-vue';
let loaded = false
  export default defineComponent({
  name: 'Header',
  methods: {
    kanban() {
      if (this.$route.path !== '/kanban') this.$router.push('/kanban')
    },
    msgPre(id: number | string) {
      console.log('msgPre id222: ', id)
      this.$router.push({ path: '/notification/allMessage/detail', query: { id: String(id) } })
    },
    msgAll() {
      console.log('msgAll')
      this.$router.push('/notification/allMessage')
    },
    user() {
      this.$router.push('/user/userinfo')
    },
    safe() {
      this.$router.push('/user/security')
    },

    async notice(msgList: any[]) {
      if (msgList.length > 0) { // 遍历消息， 弹窗提示
        console.log('有新消息了~')
        msgList.forEach((item, index) => {
          setTimeout(() => {
            notification.open({
              message: "消息通知",
              description: `${item.title}  ${item.createdAt}`,
              style: { top: `50px` },
              onClick: () => {
                this.$router.push({ path: '/notification/allMessage/detail', query: { id: item.msgId } })
              }
            })
          }, 300 * index)
        })
      }
    }
  }
})
