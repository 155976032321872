import { message } from 'ant-design-vue'
const sameModule = {
    namespaced: true,//开启命名空间 
    state: () => ({
        //复制   页面调用(this as any).$store.state.same.copy(val)
        copy(val) {
            const textarea = document.createElement("textarea");
            textarea.setAttribute("readonly", "readonly");
            textarea.value = val;
            document.body.appendChild(textarea);
            textarea.select();
            if (document.execCommand("copy")) {
                document.execCommand("copy");
                message.success(`已复制 ${val}`);
            }
            document.body.removeChild(textarea);
        },
    }),
    mutations: {
        //修改模块中的状态   页面调用(this as any).$store.commit('same/setTest',3)
        // setTest(state, test) {
        //     state.test = test;
        // }
    },
    actions: {},
    getters: {}
}
export default sameModule