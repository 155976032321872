import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Layout from "../views/Layout.vue"
import store from "../store";
import { afterLogout } from "@/utils/requestFunction.js";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "/",
    redirect: "/logList",
    component: Layout,
    children: []
  },
  {
    path: "/log",
    redirect: "/logList",
    name: "日志中心",
    component: Layout,
    meta: { hidden: false, title: "日志中心", },
    children: [
      {
        path: "/logList",
        name: "日志管理",
        component: () => import("@/views/log/List.vue"),
        meta: { hidden: false, title: "日志管理" },
        // children: [
        //   {
        //     name: "List",
        //     path: "/aa/list",
        //     meta: { hidden: false, title: "List", },
        //     component: () =>
        //       import("@/views/log/List.vue"),
        //   },
        // ]
      },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.state.isLogin === false) {
    afterLogout().then((res) => {
      if (res.data.success) {
        store.commit("changeisLogin", true);
        next();
      }
    });
  } else {
    next();
  }
});
export default router;
